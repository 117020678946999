<template>
    <div class="WmaCostPriceEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="editForm" label-width="200px" :model="detail" @submit.native.prevent size="small">
                <el-form-item label="机构类型">
                    <el-input readonly v-model="detail.deptTypeName" />
                </el-form-item>
                <el-form-item label="机构名称">
                    <el-input readonly v-model="detail.deptName" />
                </el-form-item>
                <el-form-item label="商品名称">
                    <el-input readonly v-model="detail.goodsName" />
                </el-form-item>
                <el-form-item label="库存">
                    <el-input readonly v-model="detail.stock" />
                </el-form-item>
                <el-form-item label="旧的移动加权成本单价(元)">
                    <el-input readonly v-model="detail.oldStockCostPrice" />
                </el-form-item>
                <el-form-item
                    label="新的移动加权成本单价(元)"
                    prop="newStockCostPrice"
                    :rules="rules.newStockCostPrice"
                >
                    <ef-price-input v-model="detail.newStockCostPrice" @change="computeWmaCostDiff" :min="0" />
                </el-form-item>
                <el-form-item label="差异总计(元)">
                    <el-input readonly v-model.number="detail.wmaCostDiff" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import EfPriceInput from 'components/EfPriceInput';
import Util from 'js/Util';

export default {
    name: 'WmaCostPriceEdit',
    components: { EfPriceInput },
    props: {
        code: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            detail: {
                code: '',
                deptTypeName: '',
                deptName: '',
                goodsName: '',
                stock: null,
                oldStockCostPrice: null,
                newStockCostPrice: null,
                wmaCostDiff: null,
            },
            rules: {
                newStockCostPrice: [{ required: true, message: '请输入新的移动加权成本单价', trigger: 'change' }],
            },
        };
    },

    mounted() {
        //查询单据详情
        (async () => {
            const rst = await this.$efApi.wmaCostPriceChangeApi.detail(this.code);
            Object.keys(this.detail).forEach((key) => {
                this.detail[key] = rst[key];
            });
            this.detail.wmaCostDiff = this.decimal(this.detail.newStockCostPrice)
                .sub(this.detail.oldStockCostPrice)
                .mul(this.detail.stock)
                .toNumber();
        })();
    },
    methods: {
        save() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                await this.$efApi.wmaCostPriceChangeApi.update(this.detail.code, this.detail.newStockCostPrice);
                this.goBackAndReload();
            });
        },
        computeWmaCostDiff() {
            const stock = this.detail.stock;
            const oldStockCostPrice = this.detail.oldStockCostPrice;
            const newStockCostPrice = this.detail.newStockCostPrice;
            if (!Util.isEmpty(stock) && !Util.isEmpty(oldStockCostPrice) && !Util.isEmpty(newStockCostPrice)) {
                this.detail.wmaCostDiff = Util.multiply(Util.subtract(newStockCostPrice, oldStockCostPrice), stock);
            } else {
                this.detail.wmaCostDiff = null;
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
